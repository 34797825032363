import React from 'react'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import { mediaQueries } from '../theme'
import Container from './shared/container'
import Link from './shared/link'
import Logo from './logo'

const OuterContainer = styled.footer`
  background-color: ${p => p.theme.colors.footer.background};
  color: ${p => p.theme.colors.footer.text};
  padding: ${p => p.theme.space[14]} 0;

  ${mediaQueries.lg} {
    padding: ${p => `${p.theme.space[21]} 0 ${p.theme.space[26]} 0`};
  }
`

const Nav = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${p => p.theme.space[8]};
  align-items: center;
  justify-items: center;

  ${mediaQueries.lg} {
    grid-template-columns: 8.25rem auto 1fr;
  }
`

const MainLogo = styled(Logo)`
  width: auto;
  height: ${p => p.theme.space[8]};
  margin-right: ${p => p.theme.space[2]};
`

const Brand = ({ siteTitle }) => {
  const theme = useTheme()
  return (
    <Link
      to="/"
      css={css`
        display: flex;
        align-items: center;
        color: ${theme.colors.footer.link};
        font-weight: ${theme.fontWeights.heading};
        font-size: ${theme.fontSizes[3]};
        ${mediaQueries.lg} {
          margin-right: auto;
        }
      `}
    >
      <MainLogo type="black-and-white" />
      {siteTitle}
    </Link>
  )
}

const NavList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${mediaQueries.lg} {
    flex-direction: row;
  }
`

const NavLink = styled(Link)`
  font-size: ${p => p.theme.fontSizes[1]};
  color: ${p => p.theme.colors.footer.link};
  padding: ${p => p.theme.space[4]};
  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[2]};
  }
  :hover {
    text-decoration: underline;
  }
`

const Copyright = ({ className }) => {
  const theme = useTheme()
  return (
    <div
      className={className}
      css={css`
        font-size: ${theme.fontSizes[1]};
        text-align: center;
        ${mediaQueries.lg} {
          font-size: ${theme.fontSizes[2]};
          margin-left: auto;
        }
      `}
    >
      © {new Date().getFullYear()} Getwebcraft Limited
    </div>
  )
}

const Footer = ({ siteTitle, className }) => (
  <OuterContainer className={className}>
    <Nav as="nav">
      <Brand siteTitle={siteTitle} />
      <NavList>
        <NavLink to="/privacy/">Privacy policy</NavLink>
        <NavLink to="/terms/">Terms of service</NavLink>
        <NavLink to="/faq/">FAQ</NavLink>
        <NavLink to="/anti-spam-policy/">Anti-Spam policy</NavLink>
        <NavLink href="mailto:contact@getform.com">contact@getform.com</NavLink>
      </NavList>
      <Copyright />
    </Nav>
  </OuterContainer>
)

export default Footer
