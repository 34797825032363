import React, { useState, useRef, forwardRef, Fragment } from 'react'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'

import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg'
import Link from './shared/link'
import Container from '../components/shared/container'
import { mediaQueries } from '../theme'
import useOnClickOutside from '../hooks/use-on-click-outside'
import { getLoginUrl } from '../utils/auth'
import Logo from './logo'
import Button from './shared/button'

const OuterContainer = styled.header`
  padding: ${p => `${p.theme.space[5]} 0`};
  background-color: ${p => p.theme.colors.white};
  box-shadow: ${p => p.theme.shadows.extra};
  position: sticky;
  top: 0;
  z-index: 1;

  ${mediaQueries.lg} {
    padding: ${p => `${p.theme.space[5]} 0`};
  }
`

const Navbar = forwardRef((props, ref) => (
  <Container
    as="nav"
    ref={ref}
    css={css`
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      ${mediaQueries.lg} {
        flex-wrap: nowrap;
      }
    `}
    {...props}
  />
))

const MainLogo = styled(Logo)`
  width: auto;
  height: ${p => p.theme.space[8]};
  margin-right: ${p => p.theme.space[2]};

  ${mediaQueries.lg} {
    height: ${p => p.theme.space[10]};
    margin-right: ${p => p.theme.space[4]};
  }
`

const NavbarBrand = ({ siteTitle, className }) => {
  const theme = useTheme()
  return (
    <Link
      to="/"
      css={css`
        display: flex;
        align-items: center;
        color: ${theme.colors.text};
        font-weight: ${theme.fontWeights.heading};
        font-size: ${theme.fontSizes[3]};

        ${mediaQueries.lg} {
          padding-right: ${theme.space[6]};
          font-size: ${theme.fontSizes[4]};
        }
      `}
      className={className}
    >
      <MainLogo />
      {siteTitle}
    </Link>
  )
}

const NavbarToggle = ({ onClick, className }) => {
  const theme = useTheme()
  return (
    <button
      type="button"
      aria-label="navbar-toggle"
      onClick={onClick}
      css={css`
        border: none;
        background: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        line-height: 0;

        ${mediaQueries.lg} {
          display: none;
        }
      `}
      className={className}
    >
      <MenuIcon
        css={css`
          width: auto;
          color: ${theme.colors.black[0]};
          height: ${theme.space[6]};
        `}
      />
    </button>
  )
}

const NavbarNav = ({ collapse, ...props }) => {
  const theme = useTheme()
  return (
    <div
      aria-label="Primary navigation"
      css={css`
        display: ${collapse ? 'none' : 'flex'};
        flex-direction: column;
        flex-basis: 100%;
        flex-grow: 1;

        margin: ${theme.space[5]} -${theme.space[6]} 0;
        padding: ${theme.space[0]} ${theme.space[6]};
        border-top: 1px solid ${theme.colors.navigation.border};

        ${mediaQueries.lg} {
          display: flex;
          flex-direction: row;
          flex-basis: auto;
          justify-content: center;
          margin: 0;
          padding: 0;
          border: none;
        }
      `}
      {...props}
    />
  )
}

const ACTIVE_LINK_CLASSNAME = 'active'

const NavLink = ({ to, partiallyActive = true, children }) => {
  const theme = useTheme()
  return (
    <Link
      variant="nav"
      to={to}
      activeClassName={ACTIVE_LINK_CLASSNAME}
      partiallyActive={partiallyActive}
      css={css`
        padding: ${theme.space[8]} ${theme.space[0]};
        text-decoration: none;
        text-align: center;
        font-size: ${theme.fontSizes[4]};
        font-weight: ${theme.fontWeights.bold};
        color: ${theme.colors.navigation.link};

        &:not(:first-of-type) {
          border-top: 1px solid ${theme.colors.navigation.border};
        }

        &:hover,
        &:focus {
          color: ${theme.colors.navigation.linkHover};
        }

        ${mediaQueries.lg} {
          display: flex;
          align-items: center;
          padding: 0 ${theme.space[6]};
          font-size: ${theme.fontSizes[2]};

          &:not(:first-of-type) {
            border-top: none;
          }
        }
      `}
    >
      {children}
    </Link>
  )
}

const UsersNav = ({ collapse, ...props }) => {
  const theme = useTheme()
  return (
    <div
      aria-label="User's navigation"
      css={css`
        display: ${collapse ? 'none' : 'flex'};
        flex-direction: column;
        width: 100%;
        margin-bottom: ${theme.space[1]};
        border-top: 1px solid ${theme.colors.navigation.border};

        ${mediaQueries.lg} {
          display: flex;
          flex-direction: row;
          width: auto;
          margin: 0;
          border-top: none;
        }
      `}
      {...props}
    />
  )
}

const SignUpButton = props => {
  const theme = useTheme()
  return (
    <Button
      as={Link}
      to="/start/"
      css={css`
        padding: ${theme.space[4]} ${theme.space[8]};
        font-size: ${theme.fontSizes[4]};
        background-color: ${theme.colors.black[0]};
        color: ${theme.colors.white};
        border-radius: ${theme.space[2]};

        ${mediaQueries.lg} {
          font-size: ${theme.fontSizes[2]};
          min-height: 3rem;
          min-width: 6.875rem;
          padding: ${theme.space[2]} ${theme.space[5]};
          margin-left: ${theme.space[5]};
        }
      `}
      {...props}
    >
      Sign up
    </Button>
  )
}

const LOGIN_URL = getLoginUrl()

const Header = ({ siteTitle, className, withNav = true, variant }) => {
  const [collapse, setCollapse] = useState(true)
  const ref = useRef()

  useOnClickOutside(ref, () => setCollapse(true))

  const handleNavClick = e => {
    if (e.target && e.target.classList.contains(ACTIVE_LINK_CLASSNAME)) {
      setCollapse(true)
    }
  }

  return (
    <OuterContainer className={className} variant={variant}>
      <Navbar ref={ref}>
        <NavbarBrand siteTitle={siteTitle} />

        {withNav && (
          <Fragment>
            <NavbarToggle onClick={() => setCollapse(!collapse)} />

            <NavbarNav onClick={handleNavClick} collapse={collapse}>
              <NavLink to="/templates/">Templates</NavLink>
              <NavLink to="/blog/">Blog</NavLink>
              <NavLink to="/use-cases/">Use cases</NavLink>
              <NavLink to="/help/">Help center</NavLink>
              <NavLink to="/pricing/">Pricing</NavLink>
            </NavbarNav>

            <UsersNav collapse={collapse}>
              <NavLink to={LOGIN_URL}>Log in</NavLink>
              <SignUpButton />
            </UsersNav>
          </Fragment>
        )}
      </Navbar>
    </OuterContainer>
  )
}

export default Header
