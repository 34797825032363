import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaQueries } from '../../theme'

const variantStyles = ({ theme: { sizes, space }, variant = 'container' }) => {
  switch (variant) {
    case 'container':
      return css`
        max-width: ${sizes.xl};
        padding: 0 ${space[6]};

        ${mediaQueries.lg} {
          padding: 0 ${space[10]};
        }
      `
    case 'containerXl':
      return css`
        max-width: ${sizes.xxl};

        ${mediaQueries.lg} {
          padding: 0 ${space[10]};
        }
      `
    case 'containerXxl':
      return css`
        max-width: ${sizes.xxl};
      `
    default:
      return
  }
}

const Container = styled.div`
  ${variantStyles}
  margin: 0 auto;
`

export default Container
