import React from 'react'
import { useTheme } from '@emotion/react'

const Logo = ({ size = '48', type, className }) => {
  const theme = useTheme()
  const [primary, secondary] =
    type === 'black-and-white'
      ? [theme.colors.white, theme.colors.gray[6]]
      : [theme.colors.black[0], theme.colors.blue[0]]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={secondary}
          d="M24,32 C28.418278,32 32,35.581722 32,40 C32,44.418278 28.418278,48 24,48 C19.581722,48 16,44.418278 16,40 C16,35.581722 19.581722,32 24,32 Z M40,32 C44.418278,32 48,35.581722 48,40 C48,44.418278 44.418278,48 40,48 C35.581722,48 32,44.418278 32,40 C32,35.581722 35.581722,32 40,32 Z M40,16 C44.418278,16 48,19.581722 48,24 C48,28.418278 44.418278,32 40,32 C35.581722,32 32,28.418278 32,24 C32,19.581722 35.581722,16 40,16 Z"
        />
        <path
          fill={primary}
          d="M8,32 C12.418278,32 16,35.581722 16,40 C16,44.418278 12.418278,48 8,48 C3.581722,48 5.41083001e-16,44.418278 0,40 C-5.41083001e-16,35.581722 3.581722,32 8,32 Z M8,16 C12.418278,16 16,19.581722 16,24 C16,28.418278 12.418278,32 8,32 C3.581722,32 5.41083001e-16,28.418278 0,24 C-5.41083001e-16,19.581722 3.581722,16 8,16 Z M24,16 C28.418278,16 32,19.581722 32,24 C32,28.418278 28.418278,32 24,32 C19.581722,32 16,28.418278 16,24 C16,19.581722 19.581722,16 24,16 Z M40,0 C44.418278,-8.11624501e-16 48,3.581722 48,8 C48,12.418278 44.418278,16 40,16 C35.581722,16 32,12.418278 32,8 C32,3.581722 35.581722,8.11624501e-16 40,0 Z M24,0 C28.418278,-8.11624501e-16 32,3.581722 32,8 C32,12.418278 28.418278,16 24,16 C19.581722,16 16,12.418278 16,8 C16,3.581722 19.581722,8.11624501e-16 24,0 Z M8,0 C12.418278,-8.11624501e-16 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 5.41083001e-16,12.418278 0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 Z"
        />
      </g>
    </svg>
  )
}

export default Logo
